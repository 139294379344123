import React from 'react'
import { DropdownOnSearchChangeData, DropdownProps } from 'semantic-ui-react'

import {
  Form,
  Currency,
  Select
} from '../../../../../../../../../components/Form'
import { SelectOption } from '../../../../../../../../../components/Form/Select'

interface EditablePartnerFieldsProps {
  partnerOptions: SelectOption[]
  searchPartner: (data: DropdownOnSearchChangeData) => void
  handlePartnerChange: (data: DropdownProps) => void
  changePartnerOnBlur: (data: DropdownProps) => void
}

export const EditablePartnerFields: React.FC<EditablePartnerFieldsProps> = ({
  partnerOptions,
  searchPartner,
  handlePartnerChange,
  changePartnerOnBlur
}) => {
  return (
    <Form.Group>
      <Select
        name="partnerId"
        label="Parceiro"
        options={partnerOptions}
        onSearchChange={(_, data) => searchPartner(data)}
        onChange={(_, data) => handlePartnerChange(data)}
        onBlur={(_, data) => changePartnerOnBlur(data)}
        width={13}
        search
        uppercase
      />
      <Currency name="partnerCommission" label="Comissão" width={3} readOnly />
    </Form.Group>
  )
}
