import React, { useContext, useEffect, useState } from 'react'
import { DropdownOnSearchChangeData, DropdownProps } from 'semantic-ui-react'
import { useForm } from 'react-final-form'
import { debounce } from 'debounce'

import { SelectOption } from '../../../../../../../../components/Form/Select'
import { AuthContext } from '../../../../../../../../contexts/AuthContext'
import { Show } from '../../../../../../../../components/Show'

import { userToSelectOption } from '../../../../../../utils/selectOptions'
import {
  useOrderPartner,
  useOrderEmployee,
  useUser,
  useOrder
} from '../../../../hooks'
import {
  ReadOnlyPartnerFields,
  EditablePartnerFields,
  ReadOnlyEmployeeFields,
  EditableEmployeeFields
} from './components'

const DEBOUNCE_TIME = 500

export const CommissionGroup: React.FC = () => {
  const { order, updateOrder } = useOrder()
  const { partner, setPartner } = useOrderPartner()
  const { employee, setEmployee } = useOrderEmployee()
  const form = useForm()
  const { user } = useContext(AuthContext)
  const { users: employees, listUsers: fetchEmployees } = useUser()
  const [employeeOptions, setEmployeeOptions] = useState<SelectOption[]>([])
  const { users: partners, listUsers: fetchPartners } = useUser()
  const [partnerOptions, setPartnerOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    if (!employee) return

    setEmployeeOptions([userToSelectOption(employee)])
    form.change('employeeId', employee.id)
    form.blur('employeeId')
  }, [employee])

  useEffect(() => {
    if (employees.length === 0) return

    setEmployeeOptions(employees.map(userToSelectOption))
  }, [employees])

  useEffect(() => {
    if (!partner) return

    setPartnerOptions([userToSelectOption(partner)])
    form.change('partnerId', partner.id)
    form.blur('partnerId')
  }, [partner])

  useEffect(() => {
    if (partners.length === 0) return

    setPartnerOptions(partners.map(userToSelectOption))
  }, [partners])

  const searchEmployee = (data: DropdownOnSearchChangeData) => {
    const { searchQuery } = data

    return debounce(() => fetchEmployees(searchQuery), DEBOUNCE_TIME)()
  }

  function handleEmployeeChange(data: DropdownProps) {
    const id = data.value
    const employee = employees.find(employee => employee.id === id)
    setEmployee(employee)
  }

  function changeEmployeeOnBlur(data: DropdownProps) {
    const value = data.value as string

    if (value === order?.employeeId) return

    updateOrder({ employeeId: value })
  }

  const searchPartner = (data: DropdownOnSearchChangeData) => {
    const { searchQuery } = data

    return debounce(() => fetchPartners(searchQuery), DEBOUNCE_TIME)()
  }

  function handlePartnerChange(data: DropdownProps) {
    const id = data.value
    const partner = partners.find(partner => partner.id === id)
    setPartner(partner)
  }

  function changePartnerOnBlur(data: DropdownProps) {
    const value = data.value as string

    if (value === order?.partnerId) return

    updateOrder({ partnerId: value })
  }

  const userIsAdmin = user?.role === 'ADMIN'
  const userIsPartner = user?.role === 'PARTNER'
  const userIsEmployee = user?.role === 'EMPLOYEE'
  const orderIsOpen = order?.status === 'OPEN'

  return (
    <>
      <Show condition={userIsAdmin && orderIsOpen}>
        <EditablePartnerFields
          partnerOptions={partnerOptions}
          searchPartner={searchPartner}
          handlePartnerChange={handlePartnerChange}
          changePartnerOnBlur={changePartnerOnBlur}
        />
        <EditableEmployeeFields
          employeeOptions={employeeOptions}
          searchEmployee={searchEmployee}
          handleEmployeeChange={handleEmployeeChange}
          changeEmployeeOnBlur={changeEmployeeOnBlur}
        />
      </Show>

      <Show condition={userIsAdmin && !orderIsOpen}>
        <ReadOnlyPartnerFields partner={partner} />
        <ReadOnlyEmployeeFields employee={employee} />
      </Show>

      <Show condition={userIsPartner}>
        <ReadOnlyPartnerFields partner={partner} />
      </Show>

      <Show condition={userIsEmployee}>
        <ReadOnlyEmployeeFields employee={employee} />
      </Show>
    </>
  )
}
