import React, { useContext, useEffect, useState, useMemo } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { AuthContext } from '../../../../../contexts/AuthContext'
import { User, getUser } from '../../../../../services/users'
import { Order } from '../../../../../services/order'
import { Person, getPerson } from '../../../../../services/person'
import { DefaultLoader } from '../../../../../components/DefaultLoader'
import { pages } from '../../../../../config/pages'

import { OrderFormatted, formatOrder } from '../../../utils/formatOrder'
import { formatPerson, PersonFormatted } from '../../../utils/formatPerson'
import { useCreateCertificateOrder, useFetchOrder } from './hooks'

interface OrderFormContextContent {
  order?: OrderFormatted
  customer?: Person
  responsible?: PersonFormatted
  partner?: User
  employee?: User
  loading: boolean
  isCertificateOrder: boolean
  setLoading: (value: boolean) => void
  setCustomer: (data?: Person) => void
  setEmployee: (data?: User) => void
  setPartner: (data?: User) => void
  getOrderState: () => OrderFormatted
  updateOrderState: (order: Order) => OrderFormatted
}

export const OrderFormContext = React.createContext<OrderFormContextContent>(
  {} as OrderFormContextContent
)

interface OrderFormProviderProps {
  children: React.ReactNode
}

export const OrderFormProvider: React.FC<OrderFormProviderProps> = ({
  children
}) => {
  const certificateCategoryId = 'fc86e2fd-34e2-4672-8fcc-3bd572b32e27'
  const [order, setOrder] = useState<OrderFormatted>()
  const [partner, setPartner] = useState<User>()
  const [employee, setEmployee] = useState<User>()
  const [loading, setLoading] = useState(false)
  const [customer, setCustomer] = useState<Person>()
  const [responsible, setResponsible] = useState<PersonFormatted>()
  const [query, setQuery] = useSearchParams()
  const { user } = useContext(AuthContext)
  const createCertificateOrder = useCreateCertificateOrder()
  const fetchOrder = useFetchOrder()
  const navigate = useNavigate()

  const isCertificateOrder = useMemo(() => {
    return order?.categoryId === certificateCategoryId
  }, [order])

  function getOrderState(): OrderFormatted {
    if (!order) throw new Error('Nenhum pedido foi selecionado')

    return order
  }

  function updateOrderState(data: Order): OrderFormatted {
    const items = data.items || order?.items || []
    const charges = data.charges || order?.charges || []
    const update = { ...order, ...formatOrder({ ...data, items, charges }) }
    setOrder(update)

    return update
  }

  function _fetchOrder(orderId: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => fetchOrder(orderId))
      .then(data => {
        if (data) return updateOrderState(data)

        navigate(`${pages.orders.path}/inclusao`)
        toast.warn('O pedido não foi encontrado, mas você pode criar um novo.')
      })
      .then(data => data && setCustomer(data.customer))
      .finally(() => setLoading(false))
  }

  function _createOrderByCertificate(certificateId: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => createCertificateOrder(certificateId))
      .then(data => updateOrderState(data))
      .then(order => setQuery({ ...query, id: order.id }))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const orderId = query.get('id')
    const certificateId = query.get('certificateId')

    if (orderId) {
      _fetchOrder(orderId)
    }

    if (!orderId && certificateId) {
      _createOrderByCertificate(certificateId)
    }
  }, [])

  useEffect(() => {
    const partnerId = order?.partnerId || customer?.partnerId || partner?.id

    if (!partnerId) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getUser(partnerId))
      .then(data => setPartner(data))
      .finally(() => setLoading(false))
  }, [customer])

  useEffect(() => {
    const employeeId = order?.employeeId || user?.id

    if (!employeeId) return

    Promise.resolve()
      .then(() => getUser(employeeId))
      .then(data => setEmployee(data))
  }, [order])

  useEffect(() => {
    if (!customer) return

    const { responsibleId } = customer

    if (!responsibleId) return

    if (responsibleId === responsible?.id) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getPerson(responsibleId))
      .then(data => setResponsible(formatPerson(data)))
      .finally(() => setLoading(false))
  }, [customer])

  return (
    <OrderFormContext.Provider
      value={{
        order,
        customer,
        responsible,
        partner,
        employee,
        loading,
        isCertificateOrder,
        setLoading,
        setCustomer,
        setEmployee,
        setPartner,
        getOrderState,
        updateOrderState
      }}
    >
      <DefaultLoader active={loading} />
      {children}
    </OrderFormContext.Provider>
  )
}
