import Joi from 'joi'

const messages = {
  categoryId: {
    required: 'Selecione uma categoria'
  },
  customerId: {
    required: 'Selecione um cliente'
  },
  employeeId: {
    required: 'Selecione um colaborador'
  },
  partnerId: {
    required: 'Selecione um parceiro'
  }
}

export const orderSchema = Joi.object({
  id: Joi.string().uuid({ version: ['uuidv4'] }),
  status: Joi.string().valid('OPEN', 'CLOSED'),
  categoryId: Joi.string()
    .uuid({ version: ['uuidv4'] })
    .required()
    .messages({ 'any.required': messages.categoryId.required }),
  customerId: Joi.string()
    .uuid({ version: ['uuidv4'] })
    .required()
    .messages({ 'any.required': messages.customerId.required }),
  employeeId: Joi.string()
    .uuid({ version: ['uuidv4'] })
    .required()
    .messages({ 'any.required': messages.employeeId.required }),
  partnerId: Joi.string()
    .uuid({ version: ['uuidv4'] })
    .required()
    .messages({ 'any.required': messages.partnerId.required }),
  serviceId: Joi.string().uuid({ version: ['uuidv4'] })
})
