import { useContext } from 'react'

import { OrderFormContext } from '../context'

export function useOrderPartner() {
  const { partner, setPartner } = useContext(OrderFormContext)

  return {
    partner,
    setPartner
  }
}
