import React, { useEffect, useMemo, useState } from 'react'
import { DropdownOnSearchChangeData, DropdownProps } from 'semantic-ui-react'
import { useForm, useField } from 'react-final-form'
import { debounce } from 'debounce'

import { Form, Select } from '../../../../../../../components/Form'
import { SelectOption } from '../../../../../../../components/Form/Select'
import { Show } from '../../../../../../../components/Show'

import { personToSelectOption } from '../../../../../utils/selectOptions'
import { useOrder, useOrderCustomer, useCustomer } from '../../../hooks'

const DEBOUNCE_TIME = 500

export const CustomerGroup: React.FC = () => {
  const { order, updateOrder } = useOrder()
  const { customer, responsible, setCustomer } = useOrderCustomer()
  const { customers, loading, listCustomers } = useCustomer()
  const [customerOptions, setCustomerOptions] = useState<SelectOption[]>([])
  const form = useForm()
  const field = useField('partnerId')

  useEffect(() => {
    if (!customer) return

    setCustomerOptions([personToSelectOption(customer)])
    form.change('customerId', customer.id)
  }, [customer])

  useEffect(() => {
    if (customers.length === 0) return

    setCustomerOptions(customers.map(personToSelectOption))
  }, [customers])

  const customerIsCompany = useMemo(() => {
    return !!customer && customer.type === 'COMPANY'
  }, [customer])

  const searchCustomer = (data: DropdownOnSearchChangeData) => {
    const { searchQuery } = data

    return debounce(() => listCustomers(searchQuery), DEBOUNCE_TIME)()
  }

  function changeCustomerOnBlur(data: DropdownProps) {
    const value = data.value as string

    if (order?.customerId === value) return

    const customer = customers.find(customer => customer.id === value)

    if (!customer) return

    setCustomer(customer)
    field.input.onChange({ target: { value: customer.partnerId } })
    field.input.onBlur()

    if (!order) return

    updateOrder({ customerId: value })
  }

  return (
    <>
      <Form.Group>
        <Select
          name="customerId"
          label="Cliente"
          options={customerOptions}
          loading={loading}
          onSearchChange={(_, data) => searchCustomer(data)}
          readOnly={order && order.status !== 'OPEN'}
          onBlur={(_, data) => changeCustomerOnBlur(data)}
          width={16}
          search
          required
          uppercase
        />
      </Form.Group>
      <Show condition={customerIsCompany}>
        <Form.Group>
          <Form.Input
            label="Responsável"
            width={6}
            value={responsible?.name}
            readOnly
          />
          <Form.Input
            label="E-mail do responsável"
            width={6}
            value={responsible?.emails}
            readOnly
          />
          <Form.Input
            label="Telefone do responsável"
            width={4}
            value={responsible?.phoneFormatted}
            readOnly
          />
        </Form.Group>
      </Show>
    </>
  )
}
